import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Updated import
import { Box, Tabs, Tab, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory2';
import RecordIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import SchoolIcon from '@mui/icons-material/School';
import DataIcon from '@mui/icons-material/BarChart'; // Import the appropriate icon for TL Data
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import mapitLogo from '../../utilities/dummy_assets/mapITlogo.png';
import YourLogo from '../../utilities/dummy_assets/MBF_Logo.png';
import smallLogo from '../../utilities/dummy_assets/MBF_Logo_2020 1.png';
import expandRight from '../../utilities/dummy_assets/Expand_left_double.png';
import expandLeft from '../../utilities/dummy_assets/Expand_left_double (1).png';
import { updateUser } from '../../redux/slices/userSlice';

// Define the default tabsConfig
const defaultTabsConfig = [
    { label: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { label: 'Inventory', icon: <InventoryIcon />, path: '/inventory' },
    { label: 'Training', icon: <SchoolIcon />, path: '/training' },
    { label: 'Project', icon: <FolderOpenOutlinedIcon />, path: '/project' },
    { label: 'Records', icon: <RecordIcon />, path: '/records' },
    { label: 'Settings', icon: <SettingsIcon />, path: '/settings' },
    { label: 'Logout', icon: <LogoutIcon />, path: '/login', isLogout: true }
];

// Define the admin tabsConfig
const adminTabsConfig = [
    { label: 'TL Data', icon: <DataIcon />, path: '/tl-data' },
    { label: 'Settings', icon: <SettingsIcon />, path: '/settings' },
    { label: 'Logout', icon: <LogoutIcon />, path: '/login', isLogout: true }
];

const CustomTab = ({ label, icon, selected, collapsed }) => (
    <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: collapsed ? '38%' : '80%',
        padding: '5px 8px',
        borderRadius: '10px',
        backgroundColor: 'transparent',
        color: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: 'black',
        }
    }}>
        {!collapsed && (
            <Typography
                variant="body1"
                sx={{
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '20px',
                    fontWeight: 400,
                    textAlign: 'left'
                }}
            >
                {label}
            </Typography>
        )}
        <Box sx={{ '.MuiSvgIcon-root': { fill: 'white' } }}>
            {icon}
        </Box>
    </Box>
);

const MenuComponent = ({ collapsed, setCollapsed }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(state => state.user.user); // Retrieve user from Redux store
    const [selectedTab, setSelectedTab] = useState(0);
    const [isTransitioning, setTransitioning] = useState(false);

    // console.log('MenuComponent',user);

    // Determine the appropriate tabsConfig based on user.is_org_admin
    // const tabsConfig = user.user.is_org_admin ? adminTabsConfig : defaultTabsConfig;

    // const tabsConfig = (user.user.college === 'Org-Admin') ? adminTabsConfig : defaultTabsConfig;
    const tabsConfig = (user.user.TL_name === 'Org-Admin') ? adminTabsConfig : defaultTabsConfig;

    useEffect(() => {
        const foundTab = tabsConfig.findIndex(tab => tab.path === location.pathname);
        if (foundTab !== -1) {
            setSelectedTab(foundTab);
        }
    }, [location.pathname, tabsConfig]);

    const handleTabChange = (event, newValue) => {
        const selected = tabsConfig[newValue];
        setTransitioning(true);
        setTimeout(() => {
            setSelectedTab(newValue);
            if (selected.isLogout) {
                dispatch(updateUser({}));
            }
            navigate(selected.path);
            setTransitioning(false);
        }, 300);
    };

    return (
        <Box sx={{
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            width: collapsed ? '7%' : '17.5%',
            backgroundColor: '#212121',
            transition: 'width 0.5s',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            zIndex: 1000,
        }}>
            <Box>
                <Box
                    onClick={() => setCollapsed(!collapsed)}
                    sx={{
                        padding: '5px',
                        display: 'flex',
                        width: '24px',
                        height: '24px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        marginLeft: collapsed ? '80px' : '225px',
                    }}
                >
                    <img src={collapsed ? expandRight : expandLeft} alt="Expand" />
                </Box>
                <Box sx={{ padding: '10px', marginInline: '1rem', marginBottom: '3rem' }}>
                    <img src={collapsed ? smallLogo : YourLogo} alt="Logo" />
                </Box>
                <Tabs
                    orientation="vertical"
                    value={false}
                    onChange={() => {}}
                    sx={{
                        '& .MuiTab-root': {
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            textTransform: 'none',
                            margin: '6px 6px',
                            marginLeft: collapsed ? '20%' : '0%',
                            padding: '4px',
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black',
                            }
                        },
                        '& .MuiTabs-flexContainer': {
                            flexDirection: 'column',
                        },
                        overflow: 'auto',
                    }}
                >
                    {tabsConfig.map((tab, index) => (
                        <Tab
                            key={tab.label}
                            label={<CustomTab label={tab.label} icon={tab.icon} selected={selectedTab === index} collapsed={collapsed} />}
                            onClick={() => handleTabChange(null, index)}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
                padding: '10px',
            }}>
                {!collapsed && (
                    <Typography sx={{
                        color: '#FFF',
                        fontFamily: 'Roboto',
                        fontSize: '24px',
                        fontWeight: 400,
                    }}>Powered By</Typography>
                )}
                <Box sx={{ width: '154px', height: '67.242px', display: 'flex', marginLeft: collapsed ? '70%' : '0%' }}>
                    <img src={mapitLogo} alt="MapIT Logo" />
                    {!collapsed && (
                        <Typography sx={{
                            color: 'var(--Primary-white, #FFF)',
                            fontFamily: 'Times New Roman',
                            fontSize: '21.495px',
                            fontWeight: 400,
                            marginTop: '40px',
                            marginLeft: '1px',
                        }}>mapit.ai</Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default MenuComponent;





// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { Box, Tabs, Tab, Typography } from '@mui/material';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import InventoryIcon from '@mui/icons-material/Inventory2';
// import RecordIcon from '@mui/icons-material/Assessment';
// import SettingsIcon from '@mui/icons-material/Settings';
// import LogoutIcon from '@mui/icons-material/Logout';
// import SchoolIcon from '@mui/icons-material/School';
// import mapitLogo from '../../utilities/dummy_assets/mapITlogo.png';
// import YourLogo from '../../utilities/dummy_assets/MBF_Logo.png';
// import smallLogo from '../../utilities/dummy_assets/MBF_Logo_2020 1.png';
// import expandRight from '../../utilities/dummy_assets/Expand_left_double.png';
// import expandLeft from '../../utilities/dummy_assets/Expand_left_double (1).png';
// import { updateUser } from '../../redux/slices/userSlice';

// const tabsConfig = [
//     { label: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
//     { label: 'Inventory', icon: <InventoryIcon />, path: '/inventory' },
//     { label: 'Records', icon: <RecordIcon />, path: '/records' },
//     { label: 'Training', icon: <SchoolIcon />, path: '/training' },
//     { label: 'Settings', icon: <SettingsIcon />, path: '/settings' },
//     { label: 'Logout', icon: <LogoutIcon />, path: '/login', isLogout: true }
// ];

// const CustomTab = ({ label, icon, selected, collapsed }) => (
//     <Box sx={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         width: collapsed ? '38%' : '80%',
//         padding: '10px 16px',
//         borderRadius: '10px',
//         backgroundColor: 'transparent', // Always transparent background
//         color: 'white',
//         '&:hover': {
//             backgroundColor: 'white',
//             color: 'black',
//         }
//     }}>
//         {!collapsed && (
//             <Typography
//                 variant="body1"
//                 sx={{
//                     fontFamily: 'Roboto, sans-serif',
//                     fontSize: '20px',
//                     fontWeight: 400,
//                     textAlign: 'left'
//                 }}
//             >
//                 {label}
//             </Typography>
//         )}
//         <Box sx={{ '.MuiSvgIcon-root': { fill: 'white' } }}>
//             {icon}
//         </Box>
//     </Box>
// );

// const MenuComponent = ({ collapsed, setCollapsed }) => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [selectedTab, setSelectedTab] = useState(0);
//     const [isTransitioning, setTransitioning] = useState(false); // State to manage transition

//     useEffect(() => {
//         const foundTab = tabsConfig.findIndex(tab => tab.path === location.pathname);
//         if (foundTab !== -1) {
//             setSelectedTab(foundTab);
//         }
//     }, [location.pathname]);

//     const handleTabChange = (event, newValue) => {
//         const selected = tabsConfig[newValue];
//         setTransitioning(true); // Start transition
//         setTimeout(() => {
//             setSelectedTab(newValue);
//             if (selected.isLogout) {
//                 dispatch(updateUser({}));
//             }
//             navigate(selected.path);
//             setTransitioning(false); // End transition
//         }, 300); // Adjust the delay as needed
//     };

//     return (
//         <Box sx={{
//             position: 'fixed',
//             left: 0,
//             top: 0,
//             bottom: 0,
//             width: collapsed ? '7%' : '17.5%',
//             backgroundColor: '#212121',
//             transition: 'width 0.5s',
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'space-between',
//             zIndex: 1000, // Ensure it's above other content
//         }}>
//             <Box>
//                 <Box
//                     onClick={() => setCollapsed(!collapsed)}
//                     sx={{
//                         padding: '5px',
//                         display: 'flex',
//                         width: '24px',
//                         height: '24px',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         cursor: 'pointer',
//                         marginLeft: collapsed ? '80px' : '225px', // Adjust as per your need
//                     }}
//                 >
//                     <img src={collapsed ? expandRight : expandLeft} alt="Expand" />
//                 </Box>
//                 <Box sx={{ padding: '10px', marginInline: '1rem', marginBottom: '3rem' }}>
//                     <img src={collapsed ? smallLogo : YourLogo} alt="Logo" />
//                 </Box>
//                 <Tabs
//                     orientation="vertical"
//                     value={false} // Using false to prevent MUI from managing the selection
//                     onChange={() => {}} // Prevent onChange events
//                     sx={{
//                         '& .MuiTab-root': {
//                             alignItems: 'flex-start',
//                             justifyContent: 'flex-start',
//                             textTransform: 'none',
//                             margin: '6px 6px',
//                             marginLeft: collapsed ? '20%' : '0%',
//                             padding: '4px',
//                             backgroundColor: 'transparent', // Ensure tabs are transparent
//                             '&:hover': {
//                                 backgroundColor: 'white',
//                                 color: 'black',
//                             }
//                         },
//                         '& .MuiTabs-flexContainer': {
//                             flexDirection: 'column',
//                         },
//                         overflow: 'auto',
//                     }}
//                 >
//                     {tabsConfig.map((tab, index) => (
//                         <Tab
//                             key={tab.label}
//                             label={<CustomTab label={tab.label} icon={tab.icon} selected={selectedTab === index} collapsed={collapsed} />}
//                             onClick={() => handleTabChange(null, index)}
//                         />
//                     ))}
//                 </Tabs>
//             </Box>
//             <Box sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 gap: '8px',
//                 padding: '10px',
//             }}>
//                 {!collapsed && (
//                     <Typography sx={{
//                         color: '#FFF',
//                         fontFamily: 'Roboto',
//                         fontSize: '24px',
//                         fontWeight: 400,
//                     }}>Powered By</Typography>
//                 )}
//                 <Box sx={{ width: '154px', height: '67.242px', display: 'flex', marginLeft: collapsed ? '70%' : '0%' }}>
//                     <img src={mapitLogo} alt="MapIT Logo" />
//                     {!collapsed && (
//                         <Typography sx={{
//                             color: 'var(--Primary-white, #FFF)',
//                             fontFamily: 'Times New Roman',
//                             fontSize: '21.495px',
//                             fontWeight: 400,
//                             marginTop: '40px',
//                             marginLeft: '1px',
//                         }}>mapit.ai</Typography>
//                     )}
//                 </Box>
//             </Box>
//         </Box>
//     );
// };

// export default MenuComponent;




// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Box from '@mui/material/Box';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import InventoryIcon from '@mui/icons-material/Inventory2';
// import RecordIcon from '@mui/icons-material/Assessment';
// import SettingsIcon from '@mui/icons-material/Settings';
// import LogoutIcon from '@mui/icons-material/Logout';
// import { Typography } from '@mui/material';
// import mapitLogo from '../../utilities/dummy_assets/mapITlogo.png';
// import YourLogo from '../../utilities/dummy_assets/MBF_Logo.png';
// import smallLogo from '../../utilities/dummy_assets/MBF_Logo_2020 1.png';
// import expandRight from '../../utilities/dummy_assets/Expand_left_double.png';
// import expandLeft from '../../utilities/dummy_assets/Expand_left_double (1).png';
// import { useDispatch } from 'react-redux';
// import { updateUser } from '../../redux/slices/userSlice';

// import SchoolIcon from '@mui/icons-material/School';

// const MenuComponent = ({ collapsed, setCollapsed }) => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const [selectedTab, setSelectedTab] = useState();

//     const handleTabChange = (event, newValue) => {
//         setSelectedTab(newValue);
//         switch (newValue) {
//             case 0:
//                 navigate('/dashboard');
//                 break;
//             case 1:
//                 navigate('/inventory');
//                 break;
//             case 2:
//                 navigate('/records');
//                 break;
//             case 3:
//                 navigate('/training');
//                 break;
//             case 4:
//                 navigate('/settings');
//                 break;
//             case 5:
//                 navigate('/login');
//                 dispatch(updateUser({}));
//                 break;
//             default:
//                 break;
//         }
//     };

//     const CustomTab = ({ label, icon }) => (
//         <Box sx={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             width: collapsed ? '38%' : '80%',
//             padding: '10px 16px',
//             borderRadius: '10px',
//             backgroundColor: selectedTab === label ? 'white' : 'transparent',
//             color: selectedTab === label ? 'black' : 'white',
//             '&:hover': {
//                 backgroundColor: 'white',
//                 color: 'black',
//             }
//         }}>
//             {!collapsed && (
//                 <Typography
//                     variant="body1"
//                     style={{
//                         fontFamily: 'Roboto, sans-serif',
//                         fontSize: '20px',
//                         fontWeight: 400,
//                         letterSpacing: '0em',
//                         textAlign: 'left'
//                     }}
//                 >
//                     {label}
//                 </Typography>
//             )}
//             <Box sx={{ '.MuiSvgIcon-root': { fill: selectedTab === label ? 'black' : 'white' } }}>
//                 {icon}
//             </Box>
//         </Box>
//     );

//     return (
//         <Box sx={{
//             width: '100%',
//             height: '100vh',
//             backgroundColor: '#212121',
//             transition: 'width 0.3s',
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'space-between',
//         }}>
//             <Box>
//                 <Box
//                     onClick={() => setCollapsed(!collapsed)}
//                     sx={{
//                         padding: '5px',
//                         display: 'flex',
//                         width: '24px',
//                         height: '24px',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         cursor: 'pointer',
//                         marginLeft: collapsed ? '38%' : '80%',
//                     }}
//                 >
//                     {collapsed ? <img src={expandRight} /> : <img src={expandLeft} />}
//                 </Box>
//                 <Box sx={{ padding: '10px', marginInline: '1rem', marginBottom: '3rem' }}>
//                     {collapsed ? (<img src={smallLogo} alt="small Logo" />) : (<img src={YourLogo} alt="Your Logo" />)}
//                 </Box>
//                 <Tabs
//                     orientation="vertical"
//                     value={selectedTab}
//                     onChange={handleTabChange}
//                     sx={{
//                         '& .MuiTab-root': {
//                             alignItems: 'flex-start',
//                             justifyContent: 'flex-start',
//                             textTransform: 'none',
//                             margin: '6px 6px',
//                             marginLeft: collapsed ? '20%' : '0%',
//                             padding: '4px',
//                         },
//                         '& .MuiTabs-flexContainer': {
//                             flexDirection: 'column',
//                         },
//                         overflow: 'auto',
//                     }}
//                 >
//                     <Tab label={<CustomTab label="Dashboard" icon={<DashboardIcon />} />} />
//                     <Tab label={<CustomTab label="Inventory" icon={<InventoryIcon />} />} />
//                     <Tab label={<CustomTab label="Records" icon={<RecordIcon />} />} />
//                     <Tab label={<CustomTab label="Training" icon={<SchoolIcon />} />} />
//                     <Tab label={<CustomTab label="Settings" icon={<SettingsIcon />} />} />
//                     <Tab label={<CustomTab label="Logout" icon={<LogoutIcon />} />} />
//                 </Tabs>
//             </Box>
//             <Box sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 gap: '8px',
//                 padding: '10px',
//             }}>
//                 {!collapsed && (
//                     <Typography sx={{
//                         color: '#FFF',
//                         fontFamily: 'Roboto',
//                         fontSize: '24px',
//                         fontStyle: 'normal',
//                         fontWeight: '400',
//                         lineHeight: 'normal'
//                     }}>Powered By</Typography>
//                 )}
//                 <Box sx={{ width: '154px', height: '67.242px', display: 'flex', marginLeft: collapsed ? '70%' : '0%' }}>
//                     <img src={mapitLogo} alt="MapIT Logo" />
//                     {!collapsed && (
//                         <Typography sx={{
//                             color: 'var(--Primary-white, #FFF)',
//                             fontFamily: 'Times New Roman',
//                             fontSize: '21.495px',
//                             fontStyle: 'normal',
//                             fontWeight: 400,
//                             lineHeight: 'normal',
//                             marginTop: '40px',
//                             marginLeft: '1px',
//                         }}>mapit.ai</Typography>
//                     )}
//                 </Box>
//             </Box>
//         </Box>
//     );
// };

// export default MenuComponent;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Box from '@mui/material/Box';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import InventoryIcon from '@mui/icons-material/Inventory2';
// import RecordIcon from '@mui/icons-material/Assessment';
// import SettingsIcon from '@mui/icons-material/Settings';
// import LogoutIcon from '@mui/icons-material/Logout';
// import { Typography } from '@mui/material';
// import mapitLogo from '../../utilities/dummy_assets/mapITlogo.png'
// import YourLogo from '../../utilities/dummy_assets/MBF_Logo.png'
// import smallLogo from '../../utilities/dummy_assets/MBF_Logo_2020 1.png'
// import expandRight from '../../utilities/dummy_assets/Expand_left_double.png'
// import expandLeft from '../../utilities/dummy_assets/Expand_left_double (1).png'
// import { useDispatch } from 'react-redux';
// import { updateUser } from '../../redux/slices/userSlice';

// import SchoolIcon from '@mui/icons-material/School';

// const MenuComponent = ({ collapsed, setCollapsed }) => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const [selectedTab, setSelectedTab] = useState();

//     const handleTabChange = (event, newValue) => {
//         setSelectedTab(newValue);
//         switch (newValue) {
//             case 0:
//                 navigate('/dashboard');
//                 break;
//             case 1:
//                 navigate('/inventory');
//                 break;
//             case 2:
//                 navigate('/records');
//                 break;
//             case 3:
//                 navigate('/training');
//                 break;
//             case 4:
//                 navigate('/settings');
//                 break;
//             case 5:
//                 navigate('/login');
//                 dispatch(updateUser({}));
//                 break;
//             default:
//                 break;
//         }
//     };

//     const CustomTab = ({ label, icon }) => (
//         <Box sx={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             width: collapsed ? '38%' : '80%',
//             padding: '10px 16px',
//             borderRadius: '10px',
//             backgroundColor: selectedTab === label ? 'white' : 'transparent',
//             color: selectedTab === label ? 'black' : 'white',
//             '&:hover': {
//                 backgroundColor: 'white',
//                 color: 'black',
//             }
//         }}>
//             {!collapsed && (
//                 <Typography
//                     variant="body1"
//                     style={{
//                         fontFamily: 'Roboto, sans-serif',
//                         fontSize: '20px',
//                         fontWeight: 400,
//                         letterSpacing: '0em',
//                         textAlign: 'left'
//                     }}
//                 >
//                     {label}
//                 </Typography>
//             )}
//             <Box sx={{ '.MuiSvgIcon-root': { fill: selectedTab === label ? 'black' : 'white' } }}>
//                 {icon}
//             </Box>
//         </Box>
//     );

//     return (
//         <Box sx={{
//             width: '100%',
//             // height: '100vh',
//             backgroundColor: '#212121',
//             transition: 'width 0.3s',
//         }}>
//             <Box
//                 onClick={() => setCollapsed(!collapsed)}
//                 sx={{
//                     padding: '5px',
//                     display: 'flex',
//                     width: '24px',
//                     height: '24px',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     cursor: 'pointer',
//                     marginLeft: collapsed ? '38%' : '80%',
//                 }}
//             >
//                 {collapsed ? <img src={expandRight} /> : <img src={expandLeft} />}
//             </Box>
//             <Box sx={{ padding: '10px', marginInline: '1rem', marginBottom: '3rem' }}>
//                 {collapsed ? (<img src={smallLogo} alt="small Logo" />) : (<img src={YourLogo} alt="Your Logo" />)}
//             </Box>

//             <Tabs
//                 orientation="vertical"
//                 value={selectedTab}
//                 onChange={handleTabChange}
//                 sx={{
//                     '& .MuiTab-root': {
//                         // height:'100%',
//                         alignItems: 'flex-start',
//                         justifyContent: 'flex-start',
//                         textTransform: 'none',
//                         margin: '6px 6px',
//                         marginLeft: collapsed ? '20%' : '0%',
//                         padding: '4px', // No padding here, it's set on the custom tab
//                     },
//                     '& .MuiTabs-flexContainer': {
//                         flexDirection: 'column',
//                     },
//                 }}
//             >
//                 <Tab label={<CustomTab label="Dashboard" icon={<DashboardIcon />} />} />
//                 <Tab label={<CustomTab label="Inventory" icon={<InventoryIcon />} />} />
//                 <Tab label={<CustomTab label="Records" icon={<RecordIcon />} />} />
//                 <Tab label={<CustomTab label="Training" icon={<SchoolIcon />} />} />
//                 <Tab label={<CustomTab label="Settings" icon={<SettingsIcon />} />} />
//                 <Tab label={<CustomTab label="Logout" icon={<LogoutIcon />} />} />
//             </Tabs>
//             <Box sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 gap: '8px',
//                 marginTop: '80px'
//             }}>
//                 {!collapsed && (<Typography sx={{
//                     color: '#FFF',
//                     fontFamily: 'Roboto',
//                     fontSize: '24px',
//                     fontStyle: 'normal',
//                     fontWeight: '400',
//                     lineHeight: 'normal'
//                 }}>Powered By</Typography>)}
//                 <Box sx={{ width: '154px', height: '67.242px', display: 'flex', marginLeft: collapsed ? '70%' : '0%' }}>
//                     <img src={mapitLogo} alt="MapIT Logo" />
//                     {!collapsed && (
//                         <Typography sx={{
//                             color: 'var(--Primary-white, #FFF)',
//                             fontFamily: 'Times New Roman',
//                             fontSize: '21.495px',
//                             fontStyle: 'normal',
//                             fontWeight: 400,
//                             lineHeight: 'normal',
//                             marginTop: '40px',
//                             marginLeft: '1px',
//                         }}>mapit.ai</Typography>
//                     )}
//                 </Box>
//             </Box>
//         </Box>
//     );
// };

// export default MenuComponent;
