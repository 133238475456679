import * as Yup from 'yup';
import React, { useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import {
  Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, IconButton, FormControl, Select, MenuItem, FormHelperText, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import EastIcon from '@mui/icons-material/East';
import dayjs from 'dayjs';
import CustomLocationDialog from './CustomLocation';
import CustomCategoryDialog from './CustomCategory';
import CustomGroupDialog from './CustomGroup';
import InventoryDetailAPIs from '../../../utilities/api/inventory/AdminInventoryDetail';
import InventoryGroupAPIs from '../../../utilities/api/group/AdminInventoryGroupList';
import InventoryLocationAPIs from '../../../utilities/api/location/AdminLocationList';
import InventoryCategoryAPIs from '../../../utilities/api/category/AdminInventoryCategoryList';
import {ImageBaseURL} from "../../../utilities/api/axios";

const taglist = ['Free', 'Issue', 'Purchase', 'Exhaustible', 'NonExhaustible', 'InLabOnly', 'HighValue'];

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Product type is required'),
  upc: Yup.number(),
  // stock_available: Yup.number().required('Stock available is required'),
  stock_available: Yup.number()
    .required('Stock available is required')
    .test('is-lte', 'Stock available cannot be more than stock total', function (value) {
      return value <= this.parent.stock_total;
    }),
  stock_total: Yup.number().required('Stock total is required'),
  group: Yup.string(),
  category: Yup.string(),
  location: Yup.string(),
  tags: Yup.array()
    .required('This field is required')
    .test('one-of-three', 'Only one of Free, Issue, Purchase can be selected', (value) => {
      const count = ['Free', 'Issue', 'Purchase'].filter(tag => value.includes(tag)).length;
      return count <= 1;
    })
    .test('one-of-required-three', 'At least one of Free, Issue, Purchase must be selected', (value) => {
      return ['Free', 'Issue', 'Purchase'].some(tag => value.includes(tag));
    })
    .test('exclusivity', 'Exhaustible and NonExhaustible cannot be selected together', (value) => {
      return !(value.includes('Exhaustible') && value.includes('NonExhaustible'));
    }),
  manufacturer: Yup.string(),
  purchase_cost: Yup.string(),
  image: Yup.mixed().nullable(),
  description: Yup.string(),
  stock_unit: Yup.string(),
  purchase_date: Yup.date().nullable(),
  warranty_expiration: Yup.date().nullable(),
});

const styledField = {
  maxWidth: '70%',
  padding: '0px 4px 8px 6px',
};

const EditInventoryDialog = ({ currentRow, open, onClose, update, setUpdate, flag, setFlag }) => {
  const user = useSelector((state) => state.user.user);
  const token = user.access;

  const [openCustomLocation, setOpenCustomLocation] = useState(false);
  const [customLocation, setCustomLocation] = useState('');
  const [openCustomCategory, setOpenCustomCategory] = useState(false);
  const [customCategory, setCustomCategory] = useState('');
  const [openCustomGroup, setOpenCustomGroup] = useState(false);
  const [customGroup, setCustomGroup] = useState('');
  const [newImage, setNewImage] = useState(null);

  const category = useSelector((state) => state.category.category_list);
  const location = useSelector((state) => state.location.location_list);
  const group = useSelector((state) => state.group.group_list);

  const categories = category.map((item) => item.name);
  const locations = location.map((item) => item.name);
  const groups = group.map((item) => item.name);

  const initialTags = [];
  if (currentRow) {
    if (currentRow.tag_1) initialTags.push(currentRow.tag_1);
    if (currentRow.tag_2) initialTags.push(currentRow.tag_2);
    if (currentRow.tag_3) initialTags.push(currentRow.tag_3);
    if (currentRow.tag_4) initialTags.push(currentRow.tag_4);
  }

  console.log('initialTags', initialTags);

  const handleConfirmLocation = () => {
    setOpenCustomLocation(false);
    InventoryLocationAPIs.LocationListPost(customLocation, token)
      .then((result) => {
        console.log(result);
        setFlag((prev) => !prev);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handleConfirmCategory = () => {
    setOpenCustomCategory(false);
    InventoryCategoryAPIs.CategoryListPost(customCategory, token)
      .then((result) => {
        console.log(result);
        setFlag((prev) => !prev);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handleConfirmGroup = () => {
    setOpenCustomGroup(false);
    InventoryGroupAPIs.GroupListPost(customGroup, token)
      .then((result) => {
        console.log(result);
        setFlag((prev) => !prev);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const formatDate = (date) => {
    return date ? dayjs(date).format('YYYY-MM-DD') : '';
  };

  const initialValues = {
    name: currentRow?.name || '',
    upc: currentRow?.upc || '',
    manufacturer: currentRow?.manufacturer || '',
    description: currentRow?.description || '',
    location: currentRow?.location || '',
    stock_available: currentRow?.stock_available || '',
    stock_total: currentRow?.stock_total || '',
    stock_unit: currentRow?.stock_unit || '',
    purchase_cost: currentRow?.purchase_cost || '',
    purchase_date: formatDate(currentRow?.purchase_date) || '',
    warranty_expiration: formatDate(currentRow?.warranty_expiration) || '',
    image: null,
    category: currentRow?.category || '',
    group: currentRow?.group || '',
    tags: initialTags,
    is_Hidden: false,
  };

  const mapTagToIndex = (tag) => {
    const tagMap = {
      'Free': '0',
      'Issue': '1',
      'Purchase': '2',
      'Exhaustible': '3',
      'NonExhaustible': '4',
      'InLabOnly': '5',
      'HighValue': '6',
    };
    return tagMap[tag] || '';
  };

  const FormField = ({ name, label, fieldType, options, heading, sx }) => {
    const [field, meta, helpers] = useField(name);

    const renderField = () => {
      switch (fieldType) {
        case 'dropdown':
          return (
            <TextField sx={sx} {...field} select fullWidth label={label} error={meta.touched && !!meta.error} helperText={meta.touched && meta.error}>
              {options.map((option) => (
                <MenuItem sx={{ color: 'black' }} key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          );
        case 'dropdown2':
          return (
            <Select multiple sx={sx} {...field} fullWidth error={meta.touched && !!meta.error} helperText={meta.touched && meta.error}>
              {options.map((option) => (
                <MenuItem sx={{ color: 'black' }} key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          );
        case 'dropdown3':
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField sx={sx} {...field} label={label} />
              <TextField sx={sx} {...field} select error={meta.touched && !!meta.error} helperText={meta.touched && meta.error}>
                {options.map((option) => (
                  <MenuItem sx={{ color: 'black' }} key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          );
        case 'date':
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                {...field}
                value={field.value ? dayjs(field.value) : null}
                onChange={(value) => helpers.setValue(value ? dayjs(value).format('YYYY-MM-DD') : '')}
                sx={sx}
                renderInput={(params) => <TextField {...params} fullWidth error={meta.touched && !!meta.error} helperText={meta.touched && meta.error} />}
              />
            </LocalizationProvider>
          );
        case 'timeInterval':
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TimePicker label="" {...field} />
              <EastIcon sx={{ padding: '5px' }} />
              <TimePicker label="" {...field} />
            </Box>
          );
        default:
          return (
            <TextField
              sx={sx}
              {...field}
              fullWidth
              label={label}
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
            />
          );
      }
    };

    return (
      <>
        <Typography variant="h6" sx={{ fontWeight: 'high', padding: '5px', color: 'grey' }}>
          {heading}
        </Typography>
        {renderField()}
      </>
    );
  };

  const FileUpload = ({ name, heading, sx, setFieldValue }) => {
    const fileInputRef = useRef(null);
  
    const handleFileChange = (event) => {
      const file = event.currentTarget.files[0];
      if (file && file.type.startsWith('image/')) { // Ensure only images are uploaded
        setFieldValue(name, file);
        setNewImage(URL.createObjectURL(file)); // Set the new image URL
      } else {
        alert('Only image files are allowed!');
      }
    };
  
    return (
      <>
        <Typography variant="h6" sx={{ fontWeight: 'high', marginBottom: 1, color: 'grey', paddingLeft: '5px' }}>
          {heading}
        </Typography>
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          accept="image/*" // Restrict file selection to images only
        />
        <Button
          variant="contained"
          onClick={() => fileInputRef.current.click()}
          sx={sx}
        >
          Upload File
        </Button>
        <ErrorMessage name={name}>
          {msg => <FormHelperText error>{msg}</FormHelperText>}
        </ErrorMessage>
      </>
    );
  };  

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Inventory</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const newTagsSet = new Set(values.tags);
          const updatedTags = Array.from(newTagsSet);

          // Ensure there are exactly 4 tags, filling the remaining with null
          while (updatedTags.length < 4) {
            updatedTags.push('');
          }

          const updatedValues = {
            ...values,
            tag_1: updatedTags[0],
            tag_2: updatedTags[1],
            tag_3: updatedTags[2],
            tag_4: updatedTags[3],
            tag1: mapTagToIndex(updatedTags[0]),
            tag2: mapTagToIndex(updatedTags[1]),
            tag3: mapTagToIndex(updatedTags[2]),
            tag4: mapTagToIndex(updatedTags[3]),
          };

          console.log("onSubmit values", updatedValues);

          const formData = new FormData();
          Object.keys(updatedValues).forEach(key => {
            if (key === 'image' && values.image) {
              formData.append(key, values.image);
            } else if (key !== 'image') {
              formData.append(key, updatedValues[key]);
            }
          });

          InventoryDetailAPIs.InventoryDetailPatch(currentRow.id, formData, token)
            .then((result) => {
              console.log('InventoryDetailPatch result', result);
              onClose();
              setUpdate((prev) => !prev);
            })
            .catch((error) => {
              console.log(error);
              alert(`${error.response.data}`);
            });
        }}
      >
        {({
          handleSubmit, values, touched, errors, handleChange, setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={10}>
                <Grid item xs={12} sm={6}>
                  <FormField name="name" label="Name" heading="Name*" sx={styledField} />
                  <FormField name="upc" label="Enter upc" fieldType="textfield" heading="Upc" sx={styledField} />
                  <FormField name="stock_available" label="" fieldType="textfield" heading="Stock Available*" sx={styledField} />
                  <FormField name="stock_total" label="" fieldType="textfield" heading="Stock Total*" sx={styledField} />
                  <FormField name="manufacturer" label="Enter Manufacturer Name" fieldType="textfield" heading="Manufacturer" sx={styledField} />
                  <Box>
                    <FormField
                      name="location"
                      label="Select location"
                      fieldType="dropdown"
                      heading="Location"
                      options={locations}
                      sx={styledField}
                    />
                    <IconButton aria-label="custom-location" onClick={() => setOpenCustomLocation(true)}>
                      <AddIcon sx={{ color: 'green' }} />
                    </IconButton>
                  </Box>
                  <CustomLocationDialog
                    openCustomLocation={openCustomLocation}
                    setCustomLocation={setCustomLocation}
                    onClose={() => setOpenCustomLocation(false)}
                    onConfirm={() => {
                      setFieldValue('location', customLocation);
                      handleConfirmLocation();
                    }}
                  />
                  <FormField name="purchase_cost" label="Enter Purchase Cost" fieldType="textfield" heading="Purchase Cost" sx={styledField} />
                  <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Machine Image</Typography>
                  {newImage ? (
                    <img src={newImage} alt="New Upload" style={{ width: '200px', height: '200px' }} />
                  ) : currentRow.image ? (
                    <img src={`${ImageBaseURL}${currentRow.image}`} alt={currentRow.image.split('/').pop()} style={{ width: '200px', height: '200px' }} />
                  ) : null}
                  <FileUpload
                    name="image"
                    // heading="Inventory Image"
                    sx={styledField}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <FormField
                      name="category"
                      label="Select Category"
                      fieldType="dropdown"
                      heading="Category"
                      options={categories}
                      sx={styledField}
                    />
                    <IconButton aria-label="custom-category" onClick={() => setOpenCustomCategory(true)}>
                      <AddIcon sx={{ color: 'green' }} />
                    </IconButton>
                  </Box>
                  <CustomCategoryDialog
                    openCustomCategory={openCustomCategory}
                    setCustomCategory={setCustomCategory}
                    onClose={() => setOpenCustomCategory(false)}
                    onConfirm={() => {
                      setFieldValue('category', customCategory);
                      handleConfirmCategory();
                    }}
                  />
                  <Box sx={{ width: '70%' }}>
                    <Box sx={{ mb: '8px', fontSize: '1rem', color: 'rgba(0, 0, 0, 0.54)' }}>
                      Tag*
                    </Box>
                    <FormControl
                      sx={{ width: '100%', padding: '10px 4px' }}
                      error={Boolean(errors.tags && touched.tags)}
                    >
                      <Select
                        multiple
                        value={values.tags}
                        onChange={(event) => {
                          setFieldValue('tags', event.target.value);
                        }}
                        renderValue={(selected) => selected.join(', ')}
                      >
                        {taglist.map((tag) => (
                          <MenuItem key={tag} value={tag}>
                            {tag}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText><ErrorMessage name="tags" /></FormHelperText>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormField
                      name="group"
                      label="Select group"
                      fieldType="dropdown"
                      heading="Group"
                      options={groups}
                      sx={styledField}
                    />
                    <IconButton aria-label="custom-group" onClick={() => setOpenCustomGroup(true)}>
                      <AddIcon sx={{ color: 'green' }} />
                    </IconButton>
                  </Box>
                  <CustomGroupDialog
                    openCustomGroup={openCustomGroup}
                    setCustomGroup={setCustomGroup}
                    onClose={() => setOpenCustomGroup(false)}
                    onConfirm={() => {
                      setFieldValue('group', customGroup);
                      handleConfirmGroup();
                    }}
                  />
                  <FormField name="stock_unit" label="" fieldType="textfield" heading="Stock Unit" sx={styledField} />
                  <FormField name="purchase_date" label="date" fieldType="date" heading="Purchase Date" sx={styledField} />
                  <FormField name="warranty_expiration" label="date" fieldType="date" heading="Warranty Expiration" sx={styledField} />
                </Grid>
              </Grid>
              <Typography sx={{fontSize:'1.2rem', color:"grey", mt:1}}>Description</Typography>
              <Field
                as={TextField}
                name="description"
                label="Description"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={values.description}
                onChange={handleChange}
                sx={{ mt: 2, mb: 1 }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                color="primary"
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '12px',
                  borderWidth: '1px',
                  borderColor: 'action.active',
                  '&:hover': {
                    borderWidth: '1px',
                    borderColor: 'action.active',
                  },
                  mr: 1,
                }}
              >
                Discard
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  color: 'common.white',
                  backgroundColor: 'primary.main',
                  borderRadius: '12px',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                Save Changes
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditInventoryDialog;
