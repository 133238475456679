import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: { user: {}},
    reducers: {
        getUserSuccess: (state, action) => {
            state.user = action.payload;
            if (state.user.user && state.user.user.TL_name === 'Org-Admin') {
                state.user.user.is_org_admin = true;
            }
        },
        getUserFail: (state, action) => {
            state.error = action.payload;
        },
        updateUser: (state, action) => {
            state.user = { ...state.user, ...action.payload }; // Only update the user part of the state
        },
        updateTokens: (state, action) => {
            state.tokens = action.payload; // Store tokens separately
        },
        updateTL: (state, action) => {
            if (state.user && state.user.user) {
                state.user.user.TL_name = action.payload.TL;
            }
        },
    },
});

export const { getUserSuccess, getUserFail, updateUser, updateTokens, updateTL } = userSlice.actions;
export default userSlice;
// import { createSlice } from '@reduxjs/toolkit';

// const userSlice = createSlice({
//     name: 'user',
//     initialState: { user: {}, tokens: {} },
//     reducers: {
//         getUserSuccess: (state, action) => {
//             state.user = action.payload;
//             if (state.user.user && state.user.user.college_name === 'Org-Admin') {
//                 state.user.user.is_org_admin = true;
//             }
//         },
//         getUserFail: (state, action) => {
//             state.error = action.payload;
//         },
//         updateUser: (state, action) => {
//             state.user = { ...state.user, ...action.payload }; // Only update the user part of the state
//         },
//         updateTokens: (state, action) => {
//             state.tokens = action.payload; // Store tokens separately
//         },
//         updateCollege: (state, action) => {
//             if (state.user && state.user.user) {
//                 state.user.user.college_name = action.payload.college;
//             }
//         },
//     },
// });

// export const { getUserSuccess, getUserFail, updateUser, updateTokens, updateCollege } = userSlice.actions;
// export default userSlice;
