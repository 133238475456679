import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppRoutes } from './routes';
import ChatBar from './components/Messaging/Chatbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAxiosInterceptors } from './utilities/api/axios';
import Reportbutton from './components/reportButton/reportButton';

function App() {
  // useAxiosInterceptors();

  return (
    <BrowserRouter>
      {/* <div className="App"> */}
        <AppRoutes />
        <Reportbutton/>
        {/* <ChatBar /> */}
        <ToastContainer />
      {/* </div> */}
    </BrowserRouter>
  );
}

export default App;


// import { BrowserRouter, Router } from 'react-router-dom';
// import './App.css';
// import { AppRoutes } from './routes';
// import { ToastContainer } from 'react-toastify';
// function App() {
//   return (
//     <BrowserRouter>
//       <AppRoutes/>
//     </BrowserRouter>
//   );
// }

// export default App;
