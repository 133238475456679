import React from 'react';
import { Box, Typography, Avatar, IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const ChatCard = ({ userName, message, date, onClick }) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                padding: '10px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '5px',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                // marginBottom: '10px',
                maxWidth: '100%',
                height: '60px',
                overflow: 'hidden',
                cursor: 'pointer',
            }}
        >
            <Avatar sx={{ marginRight: '10px' }} />
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 'bold',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {userName}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    You: {message}
                </Typography>
            </Box>
            <Box sx={{ textAlign: 'right', alignSelf: 'flex-start' }}>
                <Typography variant="caption" color="textSecondary">
                    {date}
                </Typography>
                <IconButton size="small" sx={{ marginLeft: '5px' }}>
                    <MoreVert fontSize="small" />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ChatCard;


// // src/components/ChatCard.js
// import React from 'react';
// import { Box, Typography, Avatar, IconButton } from '@mui/material';
// import { MoreVert } from '@mui/icons-material';

// const ChatCard = ({ userName, message, date }) => {
//     return (
//         <Box
//             sx={{
//                 display: 'flex',
//                 alignItems: 'flex-start', // Align items to the top
//                 padding: '10px',
//                 backgroundColor: '#fff',
//                 border: '1px solid #ddd',
//                 borderRadius: '5px',
//                 boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
//                 // marginBottom: '10px', // Added marginBottom back for spacing between cards
//                 maxWidth: '100%', // Ensuring card does not overflow the parent
//                 height: '60px', // Fixed height for the card
//                 overflow: 'hidden', // Ensuring content overflow is hidden
//             }}
//         >
//             <Avatar sx={{ marginRight: '10px' }} />
//             <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
//                 <Typography
//                     variant="body1"
//                     sx={{
//                         fontWeight: 'bold',
//                         overflow: 'hidden',
//                         textOverflow: 'ellipsis',
//                         whiteSpace: 'nowrap',
//                     }}
//                 >
//                     {userName}
//                 </Typography>
//                 <Typography
//                     variant="body2"
//                     color="textSecondary"
//                     sx={{
//                         overflow: 'hidden',
//                         textOverflow: 'ellipsis',
//                         whiteSpace: 'nowrap',
//                     }}
//                 >
//                     You: {message}
//                 </Typography>
//             </Box>
//             <Box sx={{ textAlign: 'right', alignSelf: 'flex-start' }}>
//                 <Typography variant="caption" color="textSecondary">
//                     {date}
//                 </Typography>
//                 <IconButton size="small" sx={{ marginLeft: '5px' }}>
//                     <MoreVert fontSize="small" />
//                 </IconButton>
//             </Box>
//         </Box>
//     );
// };

// export default ChatCard;
