import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, Paper, TextField, IconButton, Avatar } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import AdminMessagingAPIs from '../../utilities/api/IMS/AdminMessagingAPIs';
import WebSocketService from '../../utilities/api/IMS/websocketService';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const IndividualChat = ({ threadId, userName, initialMessages, onBack, addChatToChatList }) => {
    const { access, user } = useSelector(state => state.user.user);
    const [messages, setMessages] = useState(initialMessages);
    const [newMessage, setNewMessage] = useState("");
    const [currentThreadId, setCurrentThreadId] = useState(threadId);
    const [pendingMessage, setPendingMessage] = useState(null);
    const [lastSentMessageId, setLastSentMessageId] = useState(null);
    const chatContainerRef = useRef(null);

    useEffect(() => {
        setMessages(initialMessages);
    }, [initialMessages]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (newMessage.trim()) {
            const messageId = uuidv4();
            setLastSentMessageId(messageId);

            const newMsg = {
                id: messageId,
                text: newMessage,
                date: new Date().toLocaleString(),
                sender: user.username,
            };
            setNewMessage("");

            if (currentThreadId === null) {
                // If there's no thread, create one and send the first message using REST API
                setPendingMessage(newMsg);
                try {
                    const newThread = await AdminMessagingAPIs.CreateThread({
                        participants: [userName],
                        messages: []
                    });
                    setCurrentThreadId(newThread.id);
                    console.log("New thread created:", newThread.id);

                    // Send the first message via REST API
                    await AdminMessagingAPIs.PostMessage(newThread.id, { content: newMsg.text });

                    // Add the message to the UI
                    setMessages(prevMessages => [...prevMessages, newMsg]);

                    // Add the new thread to the chat list in ChatBar
                    updateChatList(newThread, newMsg);

                    // After the first message, connect to WebSocket for future messages
                    WebSocketService.connect(access, userName);
                } catch (error) {
                    console.error("Error creating new thread or sending first message:", error);
                    setPendingMessage(null);
                }
            } else {
                // If there's already a thread, send the message using WebSocket
                setMessages(prevMessages => [...prevMessages, newMsg]);
                WebSocketService.sendMessage(newMsg.text, currentThreadId);
            }
        }
    };

    const updateChatList = (newThread, newMsg) => {
        if (typeof addChatToChatList === 'function') {
            const newChat = {
                id: newThread.id,
                userName: userName,
                message: newMsg.text,
                date: newMsg.date,
                otherUser: userName
            };
            addChatToChatList(newChat);
        }
    };

    const handleIncomingMessage = useCallback((messageData) => {
        console.log("Received incoming message:", messageData);

        // Ignore the message if it matches the last sent message ID
        if (messageData.message === lastSentMessageId) {
            return;
        }

        const incomingMessage = {
            text: messageData.message,
            date: new Date().toLocaleString(),
            sender: messageData.sender
        };

        setMessages(prevMessages => [...prevMessages, incomingMessage]);
    }, [lastSentMessageId]);

    useEffect(() => {
        if (currentThreadId !== null) {
            WebSocketService.connect(access, userName);
            WebSocketService.onMessage(handleIncomingMessage);
        }

        return () => {
            WebSocketService.disconnect();
        };
    }, [access, userName, handleIncomingMessage, currentThreadId]);

    useEffect(() => {
        if (pendingMessage && currentThreadId) {
            WebSocketService.sendMessage(pendingMessage.text, currentThreadId);
            setPendingMessage(null);
        }
    }, [pendingMessage, currentThreadId]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}>
                <IconButton onClick={onBack}>
                    <ArrowBack />
                </IconButton>
                <Avatar src="path_to_profile_image" sx={{ marginLeft: '10px' }} />
                <Typography variant="h6" sx={{ marginLeft: '10px' }}>
                    {userName}
                </Typography>
            </Box>
            <Box ref={chatContainerRef} sx={{ flexGrow: 1, overflowY: 'auto', padding: '10px', backgroundColor: '#e5ddd5' }}>
                {messages.map((message, index) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: message.sender === user.username ? 'flex-end' : 'flex-start', marginBottom: '10px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: message.sender === user.username ? '#dcf8c6' : '#fff', borderRadius: '10px', padding: '10px', maxWidth: '60%', boxShadow: '0px 1px 2px rgba(0,0,0,0.1)' }}>
                            {message.sender !== user.username && (
                                <Avatar src="path_to_sender_image" sx={{ width: 30, height: 30, marginRight: '10px' }} />
                            )}
                            <Typography variant="body1">{message.text}</Typography>
                        </Box>
                        <Typography variant="caption" color="textSecondary" sx={{ marginTop: '2px' }}>
                            {message.date}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Paper component="form" onSubmit={handleSendMessage} sx={{ display: 'flex', alignItems: 'center', padding: '0 10px', boxShadow: 'none', borderTop: '1px solid #ddd', position: 'sticky', bottom: 0, backgroundColor: '#fff' }}>
                <TextField
                    sx={{ flex: 1 }}
                    placeholder="Type a message"
                    value={newMessage}
                    onChange={handleInputChange}
                    variant="outlined"
                    InputProps={{ 'aria-label': 'type a message' }}
                />
                <IconButton type="submit" aria-label="send">
                    <SendIcon />
                </IconButton>
            </Paper>
        </Box>
    );
};

export default IndividualChat;




///////////////////////////////////////////////////////////
//   WORKING WEBSOCKETS

// import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { Box, Typography, Paper, TextField, IconButton, Avatar } from '@mui/material';
// import ArrowBack from '@mui/icons-material/ArrowBack';
// import SendIcon from '@mui/icons-material/Send';
// import AdminMessagingAPIs from '../../utilities/api/IMS/AdminMessagingAPIs';
// import WebSocketService from '../../utilities/api/IMS/websocketService';
// import { useSelector } from 'react-redux';
// import { v4 as uuidv4 } from 'uuid'; // Importing UUID to generate unique message IDs

// const IndividualChat = ({ threadId, userName, initialMessages, onBack }) => {
//     const { access, user } = useSelector(state => state.user.user);
//     const [messages, setMessages] = useState(initialMessages);
//     const [newMessage, setNewMessage] = useState("");
//     const [currentThreadId, setCurrentThreadId] = useState(threadId);
//     const [pendingMessage, setPendingMessage] = useState(null);
//     const [lastSentMessageId, setLastSentMessageId] = useState(null); // To track the last sent message ID
//     const chatContainerRef = useRef(null);

//     useEffect(() => {
//         setMessages(initialMessages);
//     }, [initialMessages]);

//     useEffect(() => {
//         if (chatContainerRef.current) {
//             chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//         }
//     }, [messages]);

//     const handleInputChange = (e) => {
//         setNewMessage(e.target.value);
//     };

//     const handleSendMessage = async (e) => {
//         e.preventDefault();
//         if (newMessage.trim()) {
//             const messageId = uuidv4(); // Generate a unique ID for the message
//             setLastSentMessageId(messageId); // Track this ID to prevent duplicate display

//             const newMsg = {
//                 id: messageId,
//                 text: newMessage,
//                 date: new Date().toLocaleString(),
//                 sender: user.username,
//             };
//             setNewMessage("");

//             if (currentThreadId === null) {
//                 setPendingMessage(newMsg);
//                 try {
//                     const newThread = await AdminMessagingAPIs.CreateThread({
//                         participants: [userName],
//                         messages: []
//                     });
//                     setCurrentThreadId(newThread.id);
//                     console.log("New thread created:", newThread.id);
//                 } catch (error) {
//                     console.error("Error creating new thread:", error);
//                     setPendingMessage(null); // Clear pending message on error
//                 }
//             } else {
//                 setMessages(prevMessages => [...prevMessages, newMsg]); // Optimistic UI update
//                 WebSocketService.sendMessage(newMessage, currentThreadId);
//             }
//         }
//     };

//     const handleIncomingMessage = useCallback((messageData) => {
//         console.log("Received incoming message:", messageData); // Log incoming messages

//         // Ignore the message if it has the same ID as the last sent message
//         if (messageData.message === lastSentMessageId) {
//             return;
//         }

//         const incomingMessage = {
//             text: messageData.message,  // Structure matches your consumer's response
//             date: new Date().toLocaleString(), // Use current time for the incoming message date
//             sender: messageData.sender
//         };

//         // Add incoming messages to the chat, except those that match the last sent message ID
//         setMessages(prevMessages => [...prevMessages, incomingMessage]);
//     }, [lastSentMessageId, user.username]);

//     useEffect(() => {
//         const roomName = userName;
//         WebSocketService.connect(access, roomName);
//         WebSocketService.onMessage(handleIncomingMessage);

//         return () => {
//             WebSocketService.disconnect();
//         };
//     }, [access, userName, handleIncomingMessage]);

//     useEffect(() => {
//         if (pendingMessage && currentThreadId) {
//             WebSocketService.sendMessage(pendingMessage.text, currentThreadId);
//             setPendingMessage(null); // Clear pending message after sending
//         }
//     }, [pendingMessage, currentThreadId]);

//     return (
//         <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
//             <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}>
//                 <IconButton onClick={onBack}>
//                     <ArrowBack />
//                 </IconButton>
//                 <Avatar src="path_to_profile_image" sx={{ marginLeft: '10px' }} />
//                 <Typography variant="h6" sx={{ marginLeft: '10px' }}>
//                     {userName}
//                 </Typography>
//             </Box>
//             <Box ref={chatContainerRef} sx={{ flexGrow: 1, overflowY: 'auto', padding: '10px', backgroundColor: '#e5ddd5' }}>
//                 {messages.map((message, index) => (
//                     <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: message.sender === user.username ? 'flex-end' : 'flex-start', marginBottom: '10px' }}>
//                         <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: message.sender === user.username ? '#dcf8c6' : '#fff', borderRadius: '10px', padding: '10px', maxWidth: '60%', boxShadow: '0px 1px 2px rgba(0,0,0,0.1)' }}>
//                             {message.sender !== user.username && (
//                                 <Avatar src="path_to_sender_image" sx={{ width: 30, height: 30, marginRight: '10px' }} />
//                             )}
//                             <Typography variant="body1">{message.text}</Typography>
//                         </Box>
//                         <Typography variant="caption" color="textSecondary" sx={{ marginTop: '2px' }}>
//                             {message.date}
//                         </Typography>
//                     </Box>
//                 ))}
//             </Box>
//             <Paper component="form" onSubmit={handleSendMessage} sx={{ display: 'flex', alignItems: 'center', padding: '0 10px', boxShadow: 'none', borderTop: '1px solid #ddd', position: 'sticky', bottom: 0, backgroundColor: '#fff' }}>
//                 <TextField
//                     sx={{ flex: 1 }}
//                     placeholder="Type a message"
//                     value={newMessage}
//                     onChange={handleInputChange}
//                     variant="outlined"
//                     InputProps={{ 'aria-label': 'type a message' }}
//                 />
//                 <IconButton type="submit" aria-label="send">
//                     <SendIcon />
//                 </IconButton>
//             </Paper>
//         </Box>
//     );
// };

// export default IndividualChat;





///////////////////////////////////////////////////////




// import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { Box, Typography, Paper, TextField, IconButton, Avatar } from '@mui/material';
// import ArrowBack from '@mui/icons-material/ArrowBack';
// import SendIcon from '@mui/icons-material/Send';
// import AdminMessagingAPIs from '../../utilities/api/IMS/AdminMessagingAPIs';
// import WebSocketService from '../../utilities/api/IMS/websocketService';
// import { useSelector } from 'react-redux';

// const IndividualChat = ({ threadId, userName, initialMessages, onBack }) => {
//     const { access, user } = useSelector(state => state.user.user);
//     const [messages, setMessages] = useState(initialMessages);
//     const [newMessage, setNewMessage] = useState("");
//     const [currentThreadId, setCurrentThreadId] = useState(threadId);
//     const [pendingMessage, setPendingMessage] = useState(null);
//     const chatContainerRef = useRef(null);

//     useEffect(() => {
//         setMessages(initialMessages);
//     }, [initialMessages]);

//     useEffect(() => {
//         if (chatContainerRef.current) {
//             chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//         }
//     }, [messages]);

//     const handleInputChange = (e) => {
//         setNewMessage(e.target.value);
//     };

//     const handleSendMessage = async (e) => {
//         e.preventDefault();
//         if (newMessage.trim()) {
//             const newMsg = {
//                 text: newMessage,
//                 date: new Date().toLocaleString(),
//                 sender: user.username
//             };
//             setNewMessage("");

//             if (currentThreadId === null) {
//                 setPendingMessage(newMsg);
//                 try {
//                     const newThread = await AdminMessagingAPIs.CreateThread({
//                         participants: [userName],
//                         messages: []
//                     });
//                     setCurrentThreadId(newThread.id);
//                     console.log("New thread created:", newThread.id);
//                 } catch (error) {
//                     console.error("Error creating new thread:", error);
//                     setPendingMessage(null); // Clear pending message on error
//                 }
//             } else {
//                 setMessages(prevMessages => [...prevMessages, newMsg]); // Optimistic UI update
//                 WebSocketService.sendMessage(newMessage, currentThreadId);
//             }
//         }
//     };

//     const handleIncomingMessage = useCallback((messageData) => {
//         console.log("Received incoming message:", messageData); // Log incoming messages
//         const incomingMessage = {
//             text: messageData.message,  // Structure matches your consumer's response
//             date: new Date().toLocaleString(), // Use current time for the incoming message date
//             sender: messageData.sender // Ensure that the sender is properly handled if available
//         };
//         setMessages(prevMessages => [...prevMessages, incomingMessage]);
//     }, []);

//     useEffect(() => {
//         const roomName = userName; // In your case, this should be properly determined based on whether it's support chat or standard chat
//         WebSocketService.connect(access, roomName);
//         WebSocketService.onMessage(handleIncomingMessage);

//         return () => {
//             WebSocketService.disconnect();
//         };
//     }, [access, userName, handleIncomingMessage]);

//     useEffect(() => {
//         if (pendingMessage && currentThreadId) {
//             WebSocketService.sendMessage(pendingMessage.text, currentThreadId);
//             setPendingMessage(null); // Clear pending message after sending
//         }
//     }, [pendingMessage, currentThreadId]);

//     return (
//         <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
//             <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}>
//                 <IconButton onClick={onBack}>
//                     <ArrowBack />
//                 </IconButton>
//                 <Avatar src="path_to_profile_image" sx={{ marginLeft: '10px' }} />
//                 <Typography variant="h6" sx={{ marginLeft: '10px' }}>
//                     {userName}
//                 </Typography>
//             </Box>
//             <Box ref={chatContainerRef} sx={{ flexGrow: 1, overflowY: 'auto', padding: '10px', backgroundColor: '#e5ddd5' }}>
//                 {messages.map((message, index) => (
//                     <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: message.sender === user.username ? 'flex-end' : 'flex-start', marginBottom: '10px' }}>
//                         <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: message.sender === user.username ? '#dcf8c6' : '#fff', borderRadius: '10px', padding: '10px', maxWidth: '60%', boxShadow: '0px 1px 2px rgba(0,0,0,0.1)' }}>
//                             {message.sender !== user.username && (
//                                 <Avatar src="path_to_sender_image" sx={{ width: 30, height: 30, marginRight: '10px' }} />
//                             )}
//                             <Typography variant="body1">{message.text}</Typography>
//                         </Box>
//                         <Typography variant="caption" color="textSecondary" sx={{ marginTop: '2px' }}>
//                             {message.date}
//                         </Typography>
//                     </Box>
//                 ))}
//             </Box>
//             <Paper component="form" onSubmit={handleSendMessage} sx={{ display: 'flex', alignItems: 'center', padding: '0 10px', boxShadow: 'none', borderTop: '1px solid #ddd', position: 'sticky', bottom: 0, backgroundColor: '#fff' }}>
//                 <TextField
//                     sx={{ flex: 1 }}
//                     placeholder="Type a message"
//                     value={newMessage}
//                     onChange={handleInputChange}
//                     variant="outlined"
//                     InputProps={{ 'aria-label': 'type a message' }}
//                 />
//                 <IconButton type="submit" aria-label="send">
//                     <SendIcon />
//                 </IconButton>
//             </Paper>
//         </Box>
//     );
// };

// export default IndividualChat;



// import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { Box, Typography, Paper, TextField, IconButton, Avatar } from '@mui/material';
// import ArrowBack from '@mui/icons-material/ArrowBack';
// import SendIcon from '@mui/icons-material/Send';
// import AdminMessagingAPIs from '../../utilities/api/IMS/AdminMessagingAPIs';
// import { useSelector } from 'react-redux';

// const IndividualChat = ({ threadId, userName, initialMessages, onBack }) => {
//     const { user } = useSelector(state => state.user.user);
//     const [messages, setMessages] = useState(initialMessages);
//     const [newMessage, setNewMessage] = useState("");
//     const [currentThreadId, setCurrentThreadId] = useState(threadId);
//     const [pendingMessage, setPendingMessage] = useState(null);

//     const chatContainerRef = useRef(null);

//     useEffect(() => {
//         setMessages(initialMessages);
//     }, [initialMessages]);

//     useEffect(() => {
//         if (chatContainerRef.current) {
//             chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//         }
//     }, [messages]);

//     const handleInputChange = (e) => {
//         setNewMessage(e.target.value);
//     };

//     const handleSendMessage = async (e) => {
//         e.preventDefault();
//         if (newMessage.trim()) {
//             const newMsg = {
//                 text: newMessage,
//                 date: new Date().toLocaleString(),
//                 sender: user.username
//             };
//             setNewMessage("");

//             if (currentThreadId === null) {
//                 setPendingMessage(newMsg);
//                 try {
//                     const newThread = await AdminMessagingAPIs.CreateThread({
//                         participants: [userName],
//                         messages: []
//                     });
//                     setCurrentThreadId(newThread.id);
//                     console.log("New thread created:", newThread.id);
//                 } catch (error) {
//                     console.error("Error creating new thread:", error);
//                     setPendingMessage(null); // Clear pending message on error
//                 }
//             } else {
//                 setMessages([...messages, newMsg]); // Add message to state optimistically
//                 await sendMessage(currentThreadId, newMsg);
//             }
//         }
//     };

//     const sendMessage = async (threadId, message) => {
//         try {
//             await AdminMessagingAPIs.PostMessage(threadId, { content: message.text });
//             setMessages(prevMessages => prevMessages.map(msg => {
//                 if (msg.id === message.id) {
//                     return { ...msg, sent: true };
//                 }
//                 return msg;
//             }));
//             setPendingMessage(null); // Clear pending message after sending
//         } catch (error) {
//             console.error("Error sending message:", error);
//         }
//     };

//     const sendPendingMessage = useCallback(async () => {
//         if (pendingMessage && currentThreadId) {
//             await sendMessage(currentThreadId, pendingMessage);
//         }
//     }, [pendingMessage, currentThreadId]);

//     useEffect(() => {
//         sendPendingMessage();
//     }, [sendPendingMessage]);

//     return (
//         <Box
//             sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 height: '100%',
//             }}
//         >
//             <Box
//                 sx={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     padding: '10px',
//                     backgroundColor: '#f5f5f5',
//                     borderBottom: '1px solid #ddd',
//                 }}
//             >
//                 <IconButton onClick={onBack}>
//                     <ArrowBack />
//                 </IconButton>
//                 <Avatar
//                     src="path_to_profile_image"
//                     sx={{ marginLeft: '10px' }}
//                 />
//                 <Typography variant="h6" sx={{ marginLeft: '10px' }}>
//                     {userName}
//                 </Typography>
//             </Box>
//             <Box
//                 ref={chatContainerRef}
//                 sx={{
//                     flexGrow: 1,
//                     overflowY: 'auto',
//                     padding: '10px',
//                     backgroundColor: '#e5ddd5',
//                 }}
//             >
//                 {messages.map((message, index) => (
//                     <Box
//                         key={index}
//                         sx={{
//                             display: 'flex',
//                             flexDirection: 'column',
//                             alignItems: message.sender === user.username ? 'flex-end' : 'flex-start',
//                             marginBottom: '10px',
//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 backgroundColor: message.sender === user.username ? '#dcf8c6' : '#fff',
//                                 borderRadius: '10px',
//                                 padding: '10px',
//                                 maxWidth: '60%',
//                                 boxShadow: '0px 1px 2px rgba(0,0,0,0.1)',
//                             }}
//                         >
//                             {message.sender !== user.username && (
//                                 <Avatar
//                                     src="path_to_sender_image"
//                                     sx={{ width: 30, height: 30, marginRight: '10px' }}
//                                 />
//                             )}
//                             <Typography variant="body1">{message.text}</Typography>
//                         </Box>
//                         <Typography variant="caption" color="textSecondary" sx={{ marginTop: '2px' }}>
//                             {message.date}
//                         </Typography>
//                     </Box>
//                 ))}
//             </Box>
//             <Paper
//                 component="form"
//                 onSubmit={handleSendMessage}
//                 sx={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     padding: '0 10px',
//                     boxShadow: 'none',
//                     borderTop: '1px solid #ddd',
//                     position: 'sticky',
//                     bottom: 0,
//                     backgroundColor: '#fff',
//                 }}
//             >
//                 <TextField
//                     sx={{ flex: 1 }}
//                     placeholder="Type a message"
//                     value={newMessage}
//                     onChange={handleInputChange}
//                     variant="outlined"
//                     InputProps={{ 'aria-label': 'type a message' }}
//                 />
//                 <IconButton type="submit" aria-label="send">
//                     <SendIcon />
//                 </IconButton>
//             </Paper>
//         </Box>
//     );
// };

// export default IndividualChat;


// // import React, { useState, useEffect, useCallback, useRef } from 'react';
// // import { Box, Typography, Paper, TextField, IconButton, Avatar } from '@mui/material';
// // import ArrowBack from '@mui/icons-material/ArrowBack';
// // import SendIcon from '@mui/icons-material/Send';
// // import AdminMessagingAPIs from '../../utilities/api/IMS/AdminMessagingAPIs';
// // import { useSelector } from 'react-redux';
// // import ChatWebSocket from '../../utilities/api/IMS/websocketService';

// // const IndividualChat = ({ threadId, userName, initialMessages, onBack }) => {
// //     const { access, user } = useSelector(state => state.user.user);
// //     const [messages, setMessages] = useState(initialMessages);
// //     const [newMessage, setNewMessage] = useState("");
// //     const [currentThreadId, setCurrentThreadId] = useState(threadId);
// //     const [pendingMessage, setPendingMessage] = useState(null);
// //     const chatContainerRef = useRef(null);
// //     const chatSocketRef = useRef(null);

// //     useEffect(() => {
// //         setMessages(initialMessages);
// //     }, [initialMessages]);

// //     useEffect(() => {
// //         if (chatContainerRef.current) {
// //             chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
// //         }
// //     }, [messages]);

// //     useEffect(() => {
// //         if (currentThreadId) {
// //             chatSocketRef.current = new ChatWebSocket(currentThreadId, handleMessage, handleError, access);
// //             chatSocketRef.current.connect();
// //         }

// //         return () => {
// //             if (chatSocketRef.current) {
// //                 chatSocketRef.current.disconnect();
// //             }
// //         };
// //     }, [currentThreadId, access]);

// //     const handleMessage = (message) => {
// //         setMessages((prevMessages) => [...prevMessages, message]);
// //     };

// //     const handleError = (error) => {
// //         console.error("WebSocket error:", error);
// //     };

// //     const handleInputChange = (e) => {
// //         setNewMessage(e.target.value);
// //     };

// //     const handleSendMessage = async (e) => {
// //         e.preventDefault();
// //         if (newMessage.trim()) {
// //             const newMsg = {
// //                 text: newMessage,
// //                 date: new Date().toLocaleString(),
// //                 sender: user.username
// //             };
// //             setNewMessage("");

// //             if (!currentThreadId) {
// //                 setPendingMessage(newMsg);
// //                 try {
// //                     const newThread = await AdminMessagingAPIs.CreateThread({
// //                         participants: [userName],
// //                         messages: []
// //                     });
// //                     setCurrentThreadId(newThread.id);
// //                     console.log("New thread created:", newThread.id);
// //                 } catch (error) {
// //                     console.error("Error creating new thread:", error);
// //                     setPendingMessage(null); // Clear pending message on error
// //                 }
// //             } else {
// //                 setMessages([...messages, newMsg]); // Add message to state optimistically
// //                 sendMessage(currentThreadId, newMsg);
// //             }
// //         }
// //     };

// //     const sendMessage = async (threadId, message) => {
// //         if (chatSocketRef.current) {
// //             chatSocketRef.current.sendMessage(message.text, message.sender);
// //         }
// //     };

// //     const sendPendingMessage = useCallback(async () => {
// //         if (pendingMessage && currentThreadId) {
// //             await sendMessage(currentThreadId, pendingMessage);
// //             setPendingMessage(null); // Clear pending message after sending
// //         }
// //     }, [pendingMessage, currentThreadId]);

// //     useEffect(() => {
// //         sendPendingMessage();
// //     }, [sendPendingMessage]);

// //     return (
// //         <Box
// //             sx={{
// //                 display: 'flex',
// //                 flexDirection: 'column',
// //                 height: '100%',
// //             }}
// //         >
// //             <Box
// //                 sx={{
// //                     display: 'flex',
// //                     alignItems: 'center',
// //                     padding: '10px',
// //                     backgroundColor: '#f5f5f5',
// //                     borderBottom: '1px solid #ddd',
// //                 }}
// //             >
// //                 <IconButton onClick={onBack}>
// //                     <ArrowBack />
// //                 </IconButton>
// //                 <Avatar
// //                     src="path_to_profile_image"
// //                     sx={{ marginLeft: '10px' }}
// //                 />
// //                 <Typography variant="h6" sx={{ marginLeft: '10px' }}>
// //                     {userName}
// //                 </Typography>
// //             </Box>
// //             <Box
// //                 ref={chatContainerRef}
// //                 sx={{
// //                     flexGrow: 1,
// //                     overflowY: 'auto',
// //                     padding: '10px',
// //                     backgroundColor: '#e5ddd5',
// //                 }}
// //             >
// //                 {messages.map((message, index) => (
// //                     <Box
// //                         key={index}
// //                         sx={{
// //                             display: 'flex',
// //                             flexDirection: 'column',
// //                             alignItems: message.sender === user.username ? 'flex-end' : 'flex-start',
// //                             marginBottom: '10px',
// //                         }}
// //                     >
// //                         <Box
// //                             sx={{
// //                                 display: 'flex',
// //                                 alignItems: 'center',
// //                                 backgroundColor: message.sender === user.username ? '#dcf8c6' : '#fff',
// //                                 borderRadius: '10px',
// //                                 padding: '10px',
// //                                 maxWidth: '60%',
// //                                 boxShadow: '0px 1px 2px rgba(0,0,0,0.1)',
// //                             }}
// //                         >
// //                             {message.sender !== user.username && (
// //                                 <Avatar
// //                                     src="path_to_sender_image"
// //                                     sx={{ width: 30, height: 30, marginRight: '10px' }}
// //                                 />
// //                             )}
// //                             <Typography variant="body1">{message.text}</Typography>
// //                         </Box>
// //                         <Typography variant="caption" color="textSecondary" sx={{ marginTop: '2px' }}>
// //                             {message.date}
// //                         </Typography>
// //                     </Box>
// //                 ))}
// //             </Box>
// //             <Paper
// //                 component="form"
// //                 onSubmit={handleSendMessage}
// //                 sx={{
// //                     display: 'flex',
// //                     alignItems: 'center',
// //                     padding: '0 10px',
// //                     boxShadow: 'none',
// //                     borderTop: '1px solid #ddd',
// //                     position: 'sticky',
// //                     bottom: 0,
// //                     backgroundColor: '#fff',
// //                 }}
// //             >
// //                 <TextField
// //                     sx={{ flex: 1 }}
// //                     placeholder="Type a message"
// //                     value={newMessage}
// //                     onChange={handleInputChange}
// //                     variant="outlined"
// //                     InputProps={{ 'aria-label': 'type a message' }}
// //                 />
// //                 <IconButton type="submit" aria-label="send">
// //                     <SendIcon />
// //                 </IconButton>
// //             </Paper>
// //         </Box>
// //     );
// // };

// // export default IndividualChat;
